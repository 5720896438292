/** 
 * AR解决方案
 */
<template>
  <v-card class="solutionAR">
    <v-banner
      single-line
      height="400"
      class="banner-one"
      elevation="0"
    >
      <v-img
          src="https://h5m.wmy.igame123.com/official_website/banner/bannerSolutionAR400_yunying.jpg"
          height="100%"
          :transition="false"
          @load="handleLoad"
          class="d-flex align-center"
          :class="imgLoad ? 'v-image__image--animation':''">
      <!-- <v-img
          src="https://h5.ophyer.cn/official_website/banner/bannerSolutionAR400.jpg"
          height="100%"
          :transition="false"
          @load="handleLoad"
          class="d-flex align-center"
          :class="imgLoad ? 'v-image__image--animation':''"> -->
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-banner>
    <!-- 2结合运营目标，提供高效解决方案 -->
    <div class="d-flex justify-center" style="width:1200px;margin:0 auto;">
      <v-card class="tabWrap warp-box max-width-1200" flat >
        <div class="public-title" style="padding-bottom:38px;">
          <p>MARKETING GOAL SOLUTION</p>
          <div>
            <!-- <span>结合运营目标，提供高效解决方案</span> -->
            <span>结合商家目标，提供高效解决方案</span>
            <span>基于多年技术沉淀 驱动交互创新，实现业务增长</span>
          </div>
        </div>
        <v-tabs class="g-tabs justify-center d-flex" v-model="tab">
          <v-tabs-slider color="#0084FF"></v-tabs-slider>
          <v-tab v-for="item in data.wrap2.items" :key="item.title" >
            {{item.h1}}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(item,index) in data.wrap2.items"
            :key="index"
            :transition="false"
          >
          <v-card color="basil"  flat class="itemStyle d-flex flex-no-wrap justify-center align-center">
            <transition name="slide-fade" appear v-show="tab == index">
                <v-card width="392" flat class="pa-0" v-show="tab == index">
                  <v-card-title v-text="item.title" class="tabTitle font-size-24 pa-0 "></v-card-title>
                  <v-card-text v-text="item.content" class="tabNew font-size-14 pa-0 mt-4"></v-card-text>
                  <v-card-text v-text="item.content1" class="tabNew font-size-14 pa-0"></v-card-text>
                </v-card>
              </transition>
              <transition name="slide-fade-reverse" appear>
              <div v-show="tab == index">
                <v-img 
                  class="tabImg"
                  max-height="260" max-width="400"
                  min-width="400" min-height="260"
                  :src="item.src"
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          size="80"
                          color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                </div>
              </transition>
          </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <!-- 3丰富的AR能力 -->
    <v-card flat class="threeWrap warp-box">
      <div class="public-title mb-9">
        <p>AR ABILITY</p>
        <div>
          <span>丰富的AR能力</span>
          <span>图像、物体、面部等多种识别方式 互动体验更强</span>
        </div>
      </div>
      <v-container class="threeCon">
        <v-row>
          <v-col cols="3" v-for="n in data.wrap1.cards" :key="n.id" >
              <div class="imgWrap">
                  <img class="img_three" :src="n.src" alt="">
                  <v-card-text v-text="n.tit" class="threeTit pa-0 text-align-center mt-3"></v-card-text>
              </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!-- 4多平台权益奖励 实现全域运营 -->
    <v-card class="fourWrap warp-box" flat>
      <div class="public-title ">
        <p>PLATFORM RIGHTS</p>
        <div>
          <!-- <span>多平台权益奖励 实现全域运营</span> -->
          <span>多平台权益奖励 实现全域运营</span>
          <span>客户可以将京东、淘宝、天猫的优惠券为权益奖励，达到给店铺导流的效果</span>
        </div>
      </div>
      <div height="460px" class="d-flex justify-center pa-0 mt-14">
        <v-container class="d-flex pa-0" style="width:unset;">
          <div class="leftWrap"> 
            <img src="https://h5.ophyer.cn/official_website/other/prize.jpg" alt="">
          </div>
          <div class="rightWrap">
            <div class="up-box">
              <p class="mb-4 mt-4">线上/线下优惠券/卡密</p>
              <img src="https://h5.ophyer.cn/official_website/other/arUp1-1.png" alt="">
            </div>
            <div class="down-box">
              <v-card-text class="font-weight-bold font-size-24 pa-0 mb-5 mt-10">数字虚拟权益</v-card-text>
                <img src="https://h5.ophyer.cn/official_website/other/arDown1-1.png" width="100%" alt="">
            </div>
          </div>
        </v-container>
      </div>
    </v-card>
    <!-- 5活动入口更灵活 为运营蓄能 -->
    <v-card class="warp1 mb-15" flat>
      <div class="public-title" style="margin-bottom:45px;margin-top:80px;">
        <p>SCENES TO BE USED</p>
        <div>
          <!-- <span>活动入口更灵活 为运营蓄能</span> -->
          <span>活动入口更灵活</span>
          <span>微信、APP、web等多种打开方式 促进活动传播</span>
        </div>
      </div>
      <div class="box">
        <v-img
          src="https://h5.ophyer.cn/official_website/other/activityAR1.png"
        />
      </div>
    </v-card>
  </v-card>
</template>

<script>
import contentData from '@/static/contentData.js'

let _this;
export default {
  name: "ARsolution",
  data(){
    return{
      data: contentData.solutionAR,
      detailIndex: 1,
      bannerLoad: false,
      imgLoad: false,
      tab:null,
      vtab: null,
    }
  },
  mounted(){
    _this = this;
    this.bannerLoad = true;
  },
  methods: {
    handleLoad: ()=>{
      _this.imgLoad = true;
      console.log("ewf")
    }
  }
}
</script>

<style lang="scss" scoped>
.public-title{
  height: unset;
  margin-bottom: 0px;
}

.warp {
  width: 1200px;
  margin: 0 auto;
  img{
    border-radius: 15px;
  }
}

.warp1{
  width: 1000px;
  margin: 0 auto;
  img{
    border-radius: 15px;
  }
}

.iconWrap{
  width:450px; 
  height:600px; 
  position: relative;
  .icon_one{
    position: absolute;
    top: 30%;
  }
  .icon_two{
    position: absolute;
    bottom: 10%;
  }
}
.warp-box {
  width: 1200px;
  margin: 0 auto;
  border: none;
}

.tabWrap{
  .tabStyle{
    font-size:24px;
    padding: 0px 59px;
  }
  .tabImg{
    border-radius: 30px;
    margin-left: 87px;
  }
  .itemStyle{
    margin-top: 35px;
  }
  .tabTitle{
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
  }
  .tabNew{
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
  }
}
.threeWrap{
  .threeCon{
    .imgWrap{
      justify-content: center;
      text-align: center;
      margin-bottom: 10px;
      .img_three{
        text-align: center;
        border-radius: 5px;
        width: 100px;
        height: 100px;
      }
    }
    .threeTit{
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 44px;
    }
  }
}
.fourWrap{
  .leftWrap{
    img{
      width: 260px;
      height: 460px;
      margin-right: 60px;
      border-radius: 10px;
    }
  }
  .rightWrap{
    height: 460px;
    .up-box{
      p{
        width: 242px;
        height: 25px;
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 34px;
      }
      .box{
        img{
          width: 140px;
          height: 100px;
          background: #E9E9E9;
          opacity: 0.9;
        }
      }
    }
    .down-box{
      height: 100px;
      p{
        width: 242px;
        height: 25px;
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 34px;
      }
    }
  }
}
</style>